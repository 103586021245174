<div class="tabs-content-grid">
  <div class="page-assets">
    @if (navItems$ | async; as navItems) {
      <nav
        mat-tab-nav-bar
        [disablePagination]="false"
        [tabPanel]="tabPanel"
        class="screen-menu">
        @for (item of navItems.subItems; track item.label) {
          <a
            #rla="routerLinkActive"
            mat-tab-link
            routerLinkActive="link_active"
            [target]="item.target || '_self'"
            [routerLink]="item.link | companyTypeBasedRoute"
            [class.disabled]="item.disabled || item.payment"
            [active]="rla.isActive"
            (click)="handleClick(item, $event)">
            {{ item.name }}
          </a>
        }
      </nav>
    }

    <div class="filters" umeAffix="">
      @if (filter) {
        <button
          mat-button
          class="filter-button"
          [matMenuTriggerFor]="filter">
          <mat-icon [ngClass]="{'text-danger': isFilters}">filter_list</mat-icon>
          <span class="filter-text">
          Filter
          <span class="filter-count">
            <ng-container *ngIf="(filteredItems === 0 || filteredItems) else totalOnly">
              ( {{ filteredItems }} of {{ total }} )
            </ng-container>
            <ng-template #totalOnly>
              <span *ngIf="total && total > 0"> ( {{ total }} ) </span>
            </ng-template>
          </span>
        </span>
        </button>
      }

      <ng-container [ngTemplateOutlet]="actions"></ng-container>

      @if (bulkMenu) {
        <button
          mat-button
          [matMenuTriggerFor]="bulkMenu">
          <mat-icon>list</mat-icon>
          Bulk load
        </button>
      }
    </div>
  </div>
  <mat-divider></mat-divider>

  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
