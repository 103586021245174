import { ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { INavigationItem, ItemInfo, MENU_LABEL } from '@core/models/navigation-item.model';
import { IResStat } from '@core/models/pagination-params.model';
import { FILTER_CONTAINER, FilterContainer } from '@core/models/utils';
import { PermissionService } from '@core/permissions/permission.service';
import { MediatorService } from '@core/services/mediator.service';
import { isNumber, size } from 'lodash/fp';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { appNavigationItems$, getSectionNavItems } from '../repository/navigation-items.repository';


@Component( {
  selector:    'ume-tabs-nav-container',
  templateUrl: './tabs-nav-container.component.html',
  styleUrls:   [ './tabs-nav-container.component.scss' ],
  providers:   [
    {
      provide:     FILTER_CONTAINER,
      useExisting: forwardRef( () => TabsNavContainerComponent ),
    },
  ],
} )
export class TabsNavContainerComponent extends FilterContainer implements OnInit, OnDestroy {
  public bulkMenu!: MatMenu;
  public isFilters: number = 0;
  public navItems: INavigationItem | null = null;
  public filteredItems: number | undefined = undefined;
  public total: number | undefined = undefined;
  public navItems$: Observable<INavigationItem | null> = appNavigationItems$
    .pipe(
      map( () => {
        return this.permisson.isUmeOps()
          ? getSectionNavItems( MENU_LABEL.ONBOARDING )
          : getSectionNavItems( MENU_LABEL.COUNTERPARTIES );
      } ),
    );

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    public _cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private mediatorService: MediatorService,
    private permisson: PermissionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._route.queryParams
      .pipe( takeUntil( this._destroy$ ) )
      .subscribe( ( params: Params ) => {
        const applicableParams = {} as Params;
        for ( const key in params ) {
          if ( key.startsWith( 'UNCOUNTABLE_' ) ) {
            continue;
          }
          applicableParams[key] = params[key];
        }

        this.isFilters = Object.values( applicableParams ).filter( p => p.length || size( p ) ).length;
        this._cdr.markForCheck();
      } );

    this.mediatorService.responseStats$
      .pipe( takeUntil( this._destroy$ ) )
      .subscribe( ( stats: IResStat ) => {
        this.filteredItems = isNumber( stats.filtered ) ? stats.filtered : undefined;
        this.total = Number( stats.total );
        this._cdr.markForCheck();
      } );
  }

// fix for console error NG0100
  // public  ngAfterContentChecked(): void {
  //   this._cdr.detectChanges();
  // }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public handleClick( item: ItemInfo, event: MouseEvent ): void {
    if ( item.payment ) {
      event.preventDefault();
      this._router.navigate( [ '/', 'plans', 'comparison' ] );
      return;
    }

    if ( item.disabled ) {
      event.preventDefault();
      this._router.navigate( [ '/', 'company', 'my-network', 'restricted-user-rights' ] );
      return;
    }
  }
}
